import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import CircledQuestionMarkIcon from '../../../public/icons/circled_question_mark.svg';

type CircledQuestionMarkProps = {
  onClickHandler: () => void;
  disabled?: boolean;
};

const StyledIconButton = styled(IconButton)(({ theme, disabled }) => ({
  fill: disabled ? theme.palette.onTertiary.main : theme.palette.primary.main,
  svg: { width: '22px', height: '22px' },
}));

export const CircledQuestionMark = ({
  onClickHandler,
  disabled,
}: CircledQuestionMarkProps) => (
  <StyledIconButton disabled={disabled} onClick={onClickHandler}>
    <CircledQuestionMarkIcon />
  </StyledIconButton>
);
