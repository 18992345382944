import type { LoadingButtonProps } from '@mui/lab';
import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

const StyledLoadingButton = styled(MuiLoadingButton)(({ theme }) => ({
  boxShadow: 'none',
  fontFamily: 'Md',

  '@media': {
    textTransform: 'uppercase',
    padding: '9px 25px',
    borderRadius: '100px',
    fontWeight: '500',
    lineHeight: 1.5,
  },

  '&:disabled': {
    border: `1px solid ${theme.palette.borders.main}`,
  },
}));

export const LoadingButton = ({
  children,
  loading,
  ...restProps
}: LoadingButtonProps) => {
  return (
    <StyledLoadingButton loading={loading} variant="contained" {...restProps}>
      {children}
    </StyledLoadingButton>
  );
};
