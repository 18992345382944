type ParsedCookie = {
  accessToken?: string;
};

export const getClientSideCookie = (cookieName: string) => {
  const cookie =
    typeof window !== 'undefined'
      ? window.document.cookie
          .split('; ')
          .find((item) => item.startsWith(cookieName))
          ?.split('=')[1]
      : '';

  if (!cookie) return cookie;

  const parsedCookie = JSON.parse(decodeURIComponent(cookie)) as ParsedCookie;

  return parsedCookie.accessToken ?? '';
};
