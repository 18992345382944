import type { ButtonProps } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const MainButton = ({
  children,
  sx,
  variant = 'contained',
  ...props
}: ButtonProps) => {
  const theme = useTheme();

  return (
    <MuiButton
      {...props}
      variant={variant}
      sx={{
        boxShadow: 'none',
        fontFamily: 'Md',

        '@media': {
          textTransform: 'uppercase',
          padding: '9px 25px',
          borderRadius: '100px',
          fontWeight: '500',
          lineHeight: 1.5,
        },

        '&:disabled': {
          border: `1px solid ${theme.palette.borders.main}`,
        },
        ...sx,
      }}
    >
      {children}
    </MuiButton>
  );
};
