import type { SwitchProps as MuiSwitchProps } from '@mui/material';
import { Switch as MuiSwitch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import type { KeyboardEvent } from 'react';
import { useController } from 'react-hook-form';
import type {
  Path,
  UnPackAsyncDefaultValues,
  UseFormSetValue,
} from 'react-hook-form';
import type { Control, PathValue } from 'react-hook-form/dist/types';

import type { StepOne, StepTwo } from '../../types';

type SwitchProps<FieldTypes extends StepOne | StepTwo> = Omit<
  MuiSwitchProps,
  'name' | 'onChange'
> & {
  control: Control<FieldTypes, StepOne | StepTwo>;
  name: Path<UnPackAsyncDefaultValues<FieldTypes>>;
  setValue: UseFormSetValue<FieldTypes>;
  locked?: boolean;
};

export const Switch = <FieldTypes extends StepOne | StepTwo>({
  control,
  name,
  disabled,
  setValue,
  locked,
  ...props
}: SwitchProps<FieldTypes>) => {
  const theme = useTheme();
  const {
    field: { value },
    field,
  } = useController({
    control,
    name,
  });
  const [visibleValue, setVisibleValue] = useState(!!value);

  const handleChange = () => {
    setVisibleValue((previousChecked) => {
      field.onChange(!previousChecked);
      return !previousChecked;
    });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (document.activeElement === event.target && event.key === 'Enter') {
      setVisibleValue((previousChecked) => !previousChecked);
      event.preventDefault();
    }
  };

  useEffect(() => {
    setVisibleValue(!!value);
  }, [value]);

  useEffect(() => {
    setValue(
      name,
      visibleValue as PathValue<
        UnPackAsyncDefaultValues<FieldTypes>,
        Path<UnPackAsyncDefaultValues<FieldTypes>>
      >,
    );
    // only want to run this when visibleValue changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleValue]);

  return (
    <MuiSwitch
      {...props}
      checked={visibleValue}
      disabled={disabled}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      sx={{
        width: '52px',
        height: '32px',
        padding: '0px',
        borderRadius: '100px',

        '& .MuiSwitch-switchBase': {
          padding: '0px',
          paddingTop: '2px',
          margin: '2px',
          transitionDuration: '350ms',

          '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: theme.palette.onPrimary.main,

            '& + .MuiSwitch-track': {
              opacity: 1,
            },

            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: locked ? 1 : 0.5,
            },
          },

          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: locked
              ? theme.palette.primary.main
              : theme.palette.backgroundTertiary.main,
            opacity: 1,
          },
        },

        '& .MuiSwitch-thumb': {
          marginLeft: '2px',
          width: '24px',
          height: '24px',
          boxShadow: 'none',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: locked ? `url(/icons/lock.svg)` : 'none',
        },

        '& .MuiSwitch-track': {
          backgroundColor: theme.palette.backgroundTertiary.main,
          opacity: 1,
        },
      }}
    />
  );
};
