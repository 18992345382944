import type { InputLabelProps } from '@mui/material';
import { InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Label = styled(InputLabel)<InputLabelProps>(({ theme }) => ({
  color: theme.palette.onSurfaceSecondary.main,
  lineHeight: '22px',
  paddingBottom: '16px',
  whiteSpace: 'normal',

  '&.Mui-disabled': {
    color: theme.palette.onTertiary.main,
  },
}));
