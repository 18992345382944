import { Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { ReactElement } from 'react';

import { DialogOverlay } from './DialogOverlay';
import { MainButton } from './MainButton';

type ErrorProps = {
  buttonText: string;
  children: ReactElement;
  open: boolean;
  onClickCallback?: (() => void) | (() => Promise<void>);
};

export const Error = ({
  buttonText,
  children,
  onClickCallback,
  open,
}: ErrorProps) => {
  const theme = useTheme();

  return (
    <DialogOverlay onClose={onClickCallback} open={open}>
      <Box sx={{ outline: 'none' }}>
        <Paper
          sx={{
            background: theme.palette.errorBackground.main,
            color: theme.palette.onSurfaceSecondary.main,
            lineHeight: '20px',
            padding: '25px 30px 30px 30px',
            borderRadius: '10px',
            backdropFilter: 'blur(2px)',
            boxShadow: '4px 4px 5px #00000030',
            maxWidth: '823px',
          }}
        >
          <Box
            sx={{
              fontSize: '1rem',
              fontFamily: 'Inter',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '40px',
            }}
          >
            <Box>{children}</Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <MainButton
              onClick={onClickCallback}
              sx={{ backgroundColor: theme.palette.error.main }}
            >
              {buttonText}
            </MainButton>
          </Box>
        </Paper>
      </Box>
    </DialogOverlay>
  );
};
