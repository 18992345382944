import jwtDecode from 'jwt-decode';
import type { ReactElement } from 'react';
import { useState, useMemo, useEffect } from 'react';

import { getClientSideCookie } from '../../utils';

import { userContext } from './userContext';
import type { UserEx, UserPartial } from './userContext';

type UserContextProviderProps = {
  children: ReactElement;
  defaultUser?: string;
  authnCookieName?: string;
};

export const UserContextProvider = ({
  children,
  defaultUser,
  authnCookieName,
}: UserContextProviderProps) => {
  const [userTokenState, setUserTokenState] = useState<string>(
    defaultUser ?? '',
  );

  const [errorStringState, setErrorStringState] = useState<string>('');
  const [userState, setUserState] = useState<UserEx | null>(null);
  const [authnCookieNameState, setAuthnCookieNameState] = useState<string>(
    authnCookieName ?? '',
  );

  useEffect(() => {
    if (authnCookieName) {
      setAuthnCookieNameState(authnCookieNameState);
    }

    if (authnCookieNameState) {
      const userToken = getClientSideCookie(authnCookieNameState);

      if (userToken) {
        const user = jwtDecode(userToken);
        setUserState(user as UserEx); // User in UserEx does not necesserily have the same content as the user variable
        setUserTokenState(userToken);
      }
    }
  }, [authnCookieName, authnCookieNameState]);

  const value = useMemo(
    () => ({
      user: userState,
      userToken: userTokenState,
      setUserToken: (userToken: string) => setUserTokenState(userToken),
      setUser: (user: UserPartial) => setUserState(user),
      resetUserContext: () => {
        setUserState(null);
        setUserTokenState('');
      },
      refreshUserData: () => {
        const userToken = getClientSideCookie(authnCookieNameState);

        if (userToken) {
          const user = jwtDecode(userToken);
          setUserState(user as UserEx);
          setUserTokenState(userToken);
        }
      },
      errorString: errorStringState,
      setErrorString: (payload: string) => setErrorStringState(payload),
      authnCookieName: authnCookieNameState,
    }),
    [
      setUserState,
      userState,
      userTokenState,
      authnCookieNameState,
      errorStringState,
    ],
  );

  return <userContext.Provider value={value}>{children}</userContext.Provider>;
};
