const skeleton = `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<rect x="0" y="0" width="200" height="200" fill="#E0E0E0"  />
<rect x="0" y="0" width="200" height="200" >
  <animate attributeName="opacity" dur="1.5s" repeatCount="indefinite" values="1; 0.5; 1" />
  <animate attributeName="fill" dur="3s" repeatCount="indefinite" values="#E0E0E0; #F5F5F5; #E0E0E0" />
  <animate attributeName="x" dur="1.5s" repeatCount="indefinite" calcMode="spline" keySplines="0.4 0 0.2 1; 0.4 0 0.2 1" keyTimes="0; 0.5; 1" values="0; 0; 200" />
</rect>
</svg>`;

export const makeSkeleton = () =>
  `data:image/svg+xml;base64,${window.btoa(skeleton)}`;
