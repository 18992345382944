import { createContext } from 'react';

export enum Steps {
  'FLOW RATES',
  'PERFORMANCE',
  'RESULTS',
}

type stepsContextState = {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  activeName: string;
  startAgain: () => void;
  formCompleted: boolean;
};

const defaultStepsContextState: stepsContextState = {
  activeIndex: 0,
  setActiveIndex: () => ({}),
  activeName: Steps[0],
  startAgain: () => defaultStepsContextState.setActiveIndex(0),
  formCompleted: false,
};

export const stepsContext = createContext<stepsContextState>(
  defaultStepsContextState,
);
