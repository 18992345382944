export const FONT = 'StoneSansIIITCCom';
export const FONT_FAMILY = ['Bk', 'Bd', 'BdCn', 'Lt', 'LtCn', 'Md', 'MdIt'];
export const FONT_WEIGHT_LIGHT = 300;
export const FONT_WEIGHT_REGULAR = 400;
export const FONT_WEIGHT_MEDIUM = 500;
export const FONT_WEIGHT_BOLD = 700;
export const FONT_WEIGHTS = [
  FONT_WEIGHT_LIGHT,
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_BOLD,
];

export const PRIMARY = '#415F77';
export const SECONDARY = '#BCCFDF';
export const TERTIARY = '#84ABC1';
export const BACKGROUND = '#F5F5F5';
export const SURFACE = '#FFFFFF';
export const ERROR = '#CC0000';
export const ERROR_BACKGROUND = '#E6E6E6';
export const ON_PRIMARY = '#FFFFFF';
export const ON_SECONDARY = '#1E3E5E';
export const ON_BACKGROUND = '#000000';
export const ON_SURFACE = '#666666';
export const ON_ERROR = '#FFFFFF';

export const TERTIARY_MUTED = '#E3ECF1';
export const ON_TERTIARY_MUTED = '#9DB5C3';
export const OPAQUE_SURFACE = '#E6E6E660';
export const DISABLED_BBACKGROUND = '#E3EBF0';
export const MENU = '#84ABC1';
export const ON_SURFACE_SECONDARY = '#333333';
export const USER_MENU = '#EBF0F3';
export const BORDERS = '#9DB5C3';
export const BACKGROUND_SECONDARY = '#E3ECF1';
export const BACKGROUND_TERTIARY = '#C7D8E2';
export const BACKGROUND_DISTANT = '#F4F7F9';
export const ON_MENU = '#CCCCCC';
