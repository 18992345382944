/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import type { NormalizedCacheObject } from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

// const httpLink = (uri: string) =>
//   new HttpLink({
//     uri: `${uri}/graphql`,
//     credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
//   });

// const createApolloClient = (uri: string) => {
//   return new ApolloClient({
//     ssrMode: typeof window === 'undefined',
//     link: from([httpLink(uri)]),
//     cache: new InMemoryCache({}),
//   });
// };

const createApolloClient = (uri: string) => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createHttpLink({
      uri,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
    cache: new InMemoryCache(),
  });
};

export const initializeApollo = (
  uri: string,
  initialState: unknown | null = null,
) => {
  const _apolloClient = apolloClient ?? createApolloClient(uri);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
};

export const addApolloState = <T extends { props?: Record<string, unknown> }>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: T,
): T => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
};

export const useApollo = (pageProps: Record<string, unknown>, uri: string) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(uri, state), [state, uri]);
  return store;
};
