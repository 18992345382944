import { styled } from '@mui/material/styles';
import type { ReactElement } from 'react';

const StyledMain = styled('main')({
  padding: '0 12px',
  position: 'inherit',
});

type ContentProps = {
  children: ReactElement;
};

export const Content = ({ children }: ContentProps) => (
  <StyledMain>{children}</StyledMain>
);
