import { Box, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMeQuery } from 'aco-swt-graphql/lib/graphql-model-client';

import { UserInfoContainer } from './UserInfoContainer';

const LeftCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.onMenu.main,
  padding: '0px',
  borderBottom: '0px',
  width: '93px',
  fontSize: '1rem',
  lineHeight: '1.375',
  verticalAlign: 'top',
}));

const RightCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.onPrimary.main,
  padding: '0px',
  paddingLeft: '18px',
  borderBottom: '0px',
  fontSize: '1rem',
  lineHeight: '1.375',
  display: 'block',
  whiteSpace: 'nowrap',
  wordBreak: 'keep-all',

  [theme.breakpoints.down('sm')]: {
    width: '55vw',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
}));

export const NoData = styled(Box)(({ theme }) => ({
  color: theme.palette.onPrimary.main,
}));

export const UserInfo = () => {
  const { data, error, loading } = useMeQuery();

  if (error) {
    return <UserInfoContainer>An error has occured</UserInfoContainer>;
  }

  if (loading) {
    return <UserInfoContainer>Loading</UserInfoContainer>;
  }

  if (data && data.me === null) {
    return <UserInfoContainer>User data unavailable</UserInfoContainer>;
  }

  return (
    <UserInfoContainer>
      {data?.me && (
        <Table>
          <TableBody>
            <TableRow>
              <LeftCell>First Name:</LeftCell>
              <RightCell>{data.me.firstname}</RightCell>
            </TableRow>
            <TableRow>
              <LeftCell>Last Name:</LeftCell>
              <RightCell>{data.me.lastname}</RightCell>
            </TableRow>
            <TableRow>
              <LeftCell>Country:</LeftCell>
              <RightCell>{data.me.countryName}</RightCell>
            </TableRow>
            <TableRow>
              <LeftCell>Email:</LeftCell>
              <RightCell>{data.me.email}</RightCell>
            </TableRow>
            <TableRow>
              <LeftCell>Office:</LeftCell>
              <RightCell>{data.me.officeCode}</RightCell>
            </TableRow>
            <TableRow>
              <LeftCell>Price List ID:</LeftCell>
              <RightCell>{data.me.priceListId}</RightCell>
            </TableRow>
            <TableRow>
              <LeftCell>Notifications:</LeftCell>
              {data.me.notification?.map((notification) => (
                <RightCell key={notification}>{notification}</RightCell>
              ))}
            </TableRow>
            <TableRow>
              <LeftCell>Roles:</LeftCell>
              {data.me.roles?.map((role) => (
                <RightCell key={role}>{role}</RightCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      )}
    </UserInfoContainer>
  );
};
