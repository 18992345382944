import type { ReactElement } from 'react';

import { useUserContext } from '../../hooks';

import { ManageTimeout } from './ManageTimeout';

type TimeoutProps = {
  children: ReactElement;
};

export const Timeout = ({ children }: TimeoutProps) => {
  const { user } = useUserContext();

  return user && user.exp ? (
    <ManageTimeout exp={user.exp}>{children}</ManageTimeout>
  ) : (
    children
  );
};
