import type { TooltipProps } from '@mui/material';
import { Tooltip as MuiToolTip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Tooltip = ({
  children,
  sx,
  ...props
}: Omit<TooltipProps, 'componentsProps'>) => {
  const theme = useTheme();

  return (
    <MuiToolTip
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            color: theme.palette.onPrimary.main,
            background: theme.palette.onTertiary.main,
            fontSize: '0.8rem',
            borderRadius: '8px',
            opacity: 0,
          },
        },
      }}
    >
      {children}
    </MuiToolTip>
  );
};
