import type { RadioGroupProps as MuiRadioGroupProps } from '@mui/material';
import {
  Radio,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Control, Path, UnPackAsyncDefaultValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { StepOne } from '../../types';

export type RadioGroupProps<FieldTypes extends StepOne> = {
  disabled?: boolean;
  items: { label: string; value: string }[];
  name: Path<UnPackAsyncDefaultValues<FieldTypes>>;
  control: Control<FieldTypes, StepOne>;
} & MuiRadioGroupProps;

const StyledRadio = styled(Radio)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.onTertiary.main : theme.palette.primary.main,

  '&.Mui-checked': {
    color: disabled
      ? theme.palette.onTertiary.main
      : theme.palette.primary.main,
  },
}));

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.onSurfaceSecondary.main,
}));

export const RadioGroup = <FieldTypes extends StepOne>({
  disabled,
  items,
  name,
  control,
}: RadioGroupProps<FieldTypes>) => (
  <FormControl>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <MuiRadioGroup
          defaultValue="TreatmentArea"
          name="radio-buttons-group"
          onChange={onChange}
          value={value}
        >
          {items.map((item) => (
            <StyledLabel
              key={item.value}
              control={<StyledRadio />}
              disabled={disabled}
              label={item.label}
              value={item.value}
            />
          ))}
        </MuiRadioGroup>
      )}
    />
  </FormControl>
);
