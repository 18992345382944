export const hints = {
  treatmentFlow: {
    heading: 'Treatment flow rate (l/s)',
    message:
      'A treatment flow rate is the maximum flow rate expected from a design scenario that requires the removal of pollutants. For a first flush design this is usually the flow rate created by a 1:1 rainfall event (the largest rainfall event that will statistically happen every year), and covers 90% of rainfall volume in a year.',
  },
  firstFlush: {
    heading: 'First Flush',
    message:
      'In a first flush design the treatment flow rate is based on a 1:1 rainfall event (the largest rainfall event that will statistically happen every year), typically covering 90% of annual rainfall volume. This 90% volume is known as the first flush. The remaining 10% of rainfall volume from high intensity rainfall is bypassed. This allows for a cost effective design that achieves excellent pollution removal.',
  },
  application: {
    heading: 'Application',
    message:
      'An application is a grouping of design scenarios with similar levels of pollution and required treatment. If the application is unknown then “all-purpose” can be chosen.',
  },
  higherWaterQuality: {
    heading: 'Higher Water Quality',
    message:
      'For design scenarios where the removal of both particle bound pollution (75% of total) and dissolved pollution (25% of total) is required. This option will add Stormclean. These scenarios include groundwater infiltration and discharge into protected areas. ',
  },
  sedimentType: {
    heading: 'Sediment Type',
    message:
      'One of the most important factors affecting removal efficiency of sedimentation devices is the size of the sediment. Sediment size in stormwater is variable, and can range from an average of 10 µm to an average of 500 µm. The size of sediment will depend on local preferences.  Larger sediment is easier to remove as it settles faster, which means higher efficiency. Five different sediment options are available to choose from.',
    bullets: [
      'Coarse (US). Known as OK-110, it is a sediment with an average size of 110µm often used in the US. This option will have the highest removal efficiency.',
      'Average (DE,AT,CH). Known as Millisil W4, it is a sediment with an average size of 63µm. It is required by the German DIBt institute when certifying sedimentation-filtration devices, required by the Austrian ÖNORM B 2506-3 when certifying filtration devices, used in Switzerland when certifying sedimentation-filtration devices and recommended by British Water (UK) in their code of practice for assessing manufactured treatment devices. This sediment makes a good default option. This option will have average removal efficiency.',
      'Average (US,UK). Known as NJCAT, it is a sediment with an average size of 75µm and required by the US NJDEP certification procedure for hydrodynamic separators. This option will have above average removal for high flow rates in Stormsed Vortex.',
      'Fine. Is a commercial blend with an average size of 50µm. This option will have below average removal efficiency.',
      'Ultra Fine (US). Is a recommended sediment size from the United States Environmental Protection Agency, with an average size of 8µm. This option will have the lowest removal efficiency. ',
    ],
    message2: 'Filtration devices are less affected by sediment size.',
  },
} as const;
