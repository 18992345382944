import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';

import { useLogout } from '../../hooks';
import { Error } from '../generic/Error';

type ManageTimeoutProps = {
  children: ReactElement;
  exp: number;
};

export const ManageTimeout = ({ children, exp }: ManageTimeoutProps) => {
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [logoutText, setLogoutText] = useState('login');
  const handleLogout = useLogout();

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExp = exp - currentTime;

    const intervalId = setInterval(() => {
      setIsTimedOut(true);
      clearInterval(intervalId);
    }, timeUntilExp * 1000);

    return () => clearInterval(intervalId);
  }, [exp]);

  const hideError = async () => {
    setLogoutText('processing...');
    await handleLogout();
    setIsTimedOut(false);
  };

  return (
    <>
      {children}
      {isTimedOut && (
        <Error buttonText={logoutText} onClickCallback={hideError} open>
          <p>You`ve been logged out. Please log back in.</p>
        </Error>
      )}
    </>
  );
};
