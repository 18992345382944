import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import type { ReactElement } from 'react';

const StyledContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '70vh',
  '@media': {
    padding: '0px',
  },
});

const Overlay = styled(Box)({
  zIndex: '-99',
  width: '100%',
  height: '100%',
  left: '0',
  top: '0',
  right: '0',
  bottom: '0',
  background: 'linear-gradient(to bottom, #D9D9D900 , #D9D9D9)',
  position: 'absolute',
});

const ImageContainer = styled(Box)({
  overflow: 'hidden',
  left: '0',
  top: '0',
  right: '0',
  bottom: '0',
  zIndex: '-100',
  position: 'absolute',
});

type IntroContainerProps = {
  children: ReactElement;
};

export const IntroContainer = ({ children }: IntroContainerProps) => (
  <>
    <Overlay />
    <ImageContainer>
      <Image
        alt="Stormwater Application Model Cross Section"
        fill
        quality={100}
        src="/images/Stormwater_Application_GRP.webp"
        sizes="(max-width: 768px) 100vw,
        (max-width: 1200px) 50vw,
        33vw"
        style={{
          objectFit: 'cover',
          objectPosition: 'top',
        }}
      />
    </ImageContainer>
    <StyledContainer>{children}</StyledContainer>
  </>
);
