export enum TreatmentReturnPeriodMeasure {
  TreatmentArea = 'treatmentArea',
  AnnualRainfallIntensity = 'annualRainfallIntensity',
}

export enum MaximumHydraulicFlowReturnPeriodMeasure {
  MaximumHydraulicFlowReturnPeriod = 'maximumHydraulicFlowReturnPeriod',
  ReturnPeriodRainfallIntensity = 'returnPeriodRainfallIntensity',
}

export type FieldInput = number | '';

export type StepOne = {
  leftColumn: StepOneLeftColumn;
  rightColumn: StepOneRightColumn;
};

export type StepTwo = {
  application: string;
  sedimentType: string;
  infiltration: boolean;
};

export type StepOneLeftColumn = {
  treatmentFlowRate: FieldInput;
  bypassFlow: boolean;
  maximumHydraulicFlow: FieldInput;
};

export type StepOneRightColumn = {
  treatmentArea: FieldInput;
  treatmentReturnPeriod: FieldInput;
  maximumHydraulicFlowReturnPeriod: FieldInput;
  treatmentReturnPeriodMeasure: TreatmentReturnPeriodMeasure;
  maximumHydraulicFlowReturnPeriodMeasure: MaximumHydraulicFlowReturnPeriodMeasure;
};

export type FormContextState = {
  flowRate: boolean;
  stepOne: StepOne;
  stepTwo: StepTwo;
};
