import type { PaperProps } from '@mui/material';
import { Paper as MuiPaper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Paper = styled(MuiPaper)<PaperProps & { disabled?: boolean }>(
  ({ theme, disabled }) => ({
    boxShadow: disabled ? 'none' : '0px 4px 4px #00000025;',
    padding: '28px 0px 0px 0px',
    textAlign: 'left',
    borderRadius: '33px',
    color: disabled
      ? theme.palette.onTertiary.main
      : theme.palette.text.primary,
    backgroundColor: disabled
      ? theme.palette.action.disabledBackground
      : theme.palette.surface.main,
  }),
);
