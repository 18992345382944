import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { TextField as MuiTextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { forwardRef } from 'react';

type TextFieldProps = MuiTextFieldProps & {
  endAdornment?: boolean;
  pseudoDisabled?: boolean;
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      autoComplete = 'off',
      error,
      disabled,
      pseudoDisabled,
      children,
      sx,
      endAdornment,
      ...props
    },
    hookFormRef,
  ) => {
    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));

    return (
      <MuiTextField
        {...props}
        ref={hookFormRef}
        autoComplete={autoComplete}
        disabled={disabled}
        error={error}
        sx={{
          zIndex: '1',
          width: isLg ? '350px' : '100%',

          '& .MuiInputBase-root': {
            padding: '0px',
            borderRadius: '33px',
            color: error
              ? theme.palette.error.main
              : theme.palette.primary.main,
            background: error
              ? theme.palette.errorBackground.main
              : theme.palette.backgroundSecondary.main,
            zIndex: '-99',

            '&.Mui-focused fieldset': {
              background: error
                ? theme.palette.errorBackground.main
                : theme.palette.backgroundSecondary.main,
              border: error
                ? `2px solid ${theme.palette.error.main}`
                : `2px solid ${theme.palette.borders.main}`,
              zIndex: '-98',
            },

            '& .MuiIconButton-root': {
              color: theme.palette.primary.main,
              left: '7px',
            },
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border:
              error && pseudoDisabled
                ? `none`
                : error
                ? `2px solid ${theme.palette.error.main}`
                : disabled
                ? 'none'
                : `1px solid ${theme.palette.borders.main}`,
          },

          '& .MuiInputBase-input': {
            padding: '11px 30px',
            paddingRight: endAdornment ? '50px' : '30px',

            '&::placeholder': {
              opacity: 1,
              color: theme.palette.tertiary.main,
            },

            '&:-webkit-autofill': {
              border: error
                ? `2px solid ${theme.palette.error.main}`
                : `1px solid ${theme.palette.borders.main}`,
              textFillColor: theme.palette.primary.main,
              WebkitTextFillColor: theme.palette.primary.main,
              boxShadow: `0 0 0 100px ${theme.palette.tertiary.muted} inset`,
              WebkitBoxShadow: `0 0 0 100px ${theme.palette.tertiary.muted} inset`,
            },
          },

          '& .Mui-disabled': {
            borderRadius: '33px',
            background: pseudoDisabled
              ? theme.palette.action.disabledBackground
              : theme.palette.backgroundTertiary.main,
            textFillColor: pseudoDisabled
              ? theme.palette.action.disabledBackground
              : theme.palette.backgroundTertiary.main,
          },

          '& input:placeholder-shown + fieldset': {
            border:
              error && pseudoDisabled
                ? 'none'
                : error
                ? `2px solid ${theme.palette.error.main}`
                : 'none',
            background: error
              ? theme.palette.errorBackground.main
              : pseudoDisabled
              ? theme.palette.action.disabledBackground
              : theme.palette.backgroundTertiary.main,
            zIndex: '-99',
          },

          '& input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },

          '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          ...sx,
        }}
      >
        {children}
      </MuiTextField>
    );
  },
);
