import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';

import type { FormContextState } from '../../types';

import {
  formContext,
  formContextDefaultState,
  defaultData,
} from './formContext';

type FormContextProviderProps = {
  children: ReactElement;
};

export const FormContextProvider = ({ children }: FormContextProviderProps) => {
  const [data, setData] = useState<FormContextState>(formContextDefaultState);

  const value = useMemo(
    () => ({
      ...data,
      setData: (payload: FormContextState) => setData(payload),
      resetData: () => setData(defaultData),
    }),
    [data],
  );

  return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
