import type { JwtPayload } from 'jwt-decode';
import { createContext } from 'react';

export type User = {
  client_id: string;
  country_name: string;
  displayName: string;
  email: string;
  firstname: string;
  lang_code: string;
  lastname: string;
  notification: string[];
  office_code: string;
  office_lang_code: string;
  office_name: string;
  price_list_id: string | number;
  region: string;
  role: string[];
  scope: string;
  userid: string;
};

export type UserPartial = Partial<User>;

export type UserEx = UserPartial & JwtPayload;

type UserContext = {
  user: UserEx | null;
  userToken: string;
  setUserToken: (userToken: string) => void;
  setUser: (user: UserPartial) => void;
  resetUserContext: () => void;
  refreshUserData: () => void;
  errorString: string;
  setErrorString: (payload: string) => void;
  authnCookieName: string;
};

const defaultUserContext: UserContext = {
  user: null,
  userToken: '',
  setUserToken: () => ({}),
  setUser: () => ({}),
  resetUserContext: () => ({}),
  refreshUserData: () => ({}),
  errorString: '',
  setErrorString: () => ({}),
  authnCookieName: '',
};

export const userContext = createContext<UserContext>(defaultUserContext);
