import type { MutableRefObject, ReactElement } from 'react';
import { useRef, useState, useMemo } from 'react';

import { stepsContext, Steps } from './stepsContext';

const checkFormCompleteness = (
  activeStep: number,
  formCompleted: MutableRefObject<boolean>,
) => {
  if (activeStep < 2) return formCompleted.current;
  formCompleted.current = true;
  return true;
};

type StepsContextProviderProps = {
  children: ReactElement;
};

export const StepsContextProvider = ({
  children,
}: StepsContextProviderProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const formCompleted = useRef(false);

  const value = useMemo(
    () => ({
      activeIndex: activeStep,
      setActiveIndex: (index: number) => setActiveStep(index),
      activeName: Steps[activeStep],
      startAgain: () => value.setActiveIndex(0),
      formCompleted: checkFormCompleteness(activeStep, formCompleted),
    }),
    [activeStep],
  );

  return (
    <stepsContext.Provider value={value}>{children}</stepsContext.Provider>
  );
};
