/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

type GTMProviderProps = {
  gtmId?: string;
};

export const GTMProvider = ({ gtmId }: GTMProviderProps) => {
  const router = useRouter();

  useEffect(() => {
    if (!gtmId) return;

    const handleRouteChange = () => {
      (window as any).window.dataLayer = (window as any).window.dataLayer || [];
      (window as any).window.dataLayer.push({ event: 'pageview' });
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [gtmId, router.events]);

  if (!gtmId) {
    return null;
  }

  return (
    <Script
      id="gtm-base-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${gtmId}');
        `,
      }}
    />
  );
};
