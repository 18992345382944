import { CssBaseline } from '@mui/material';
import type { ReactElement } from 'react';

import { Content } from './content';
import { Header } from './header';

type LayoutProps = { children: ReactElement; configuratorLink?: string };

export const Layout = ({ children, configuratorLink }: LayoutProps) => (
  <CssBaseline>
    <Header configuratorLink={configuratorLink} />
    <Content>{children}</Content>
  </CssBaseline>
);
