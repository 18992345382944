import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  ClickAwayListener,
  Paper,
  Typography,
  IconButton,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';

import { hints } from '../../constants/hints';

import { DialogOverlay } from './DialogOverlay';

type ModalProps = {
  heading: string;
  open: boolean;
  onClickCallback: () => void;
  message: string;
  sedimentType?: boolean;
};

export const StyledPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.primary.main,
  lineHeight: '22px',
  padding: 40,
  borderRadius: 20,
  boxShadow: '0px 2px 3px 0px #00000030',
  maxWidth: 740,
}));

export const HeadingBox = styled('div')({
  display: 'flex',
  width: '100%',
  marginBottom: '40px',
});

export const StyledHeading = styled(Typography)(({ theme }) => ({
  '@media': {
    fontSize: '1.5rem',
    color: theme.palette.text.primary,
  },
}));

export const CloseIconButton = styled(IconButton)({
  cursor: 'pointer',
  marginLeft: 'auto',
  padding: '0 8px',
  svg: { width: '24px', height: '24px' },
});

export const HintModal = ({
  heading,
  message,
  onClickCallback,
  open,
  sedimentType,
}: ModalProps) => {
  const theme = useTheme();

  return (
    <DialogOverlay onClose={onClickCallback} open={open}>
      <Box sx={{ outline: 'none' }}>
        <ClickAwayListener onClickAway={onClickCallback}>
          <StyledPaper>
            <HeadingBox>
              <StyledHeading>{heading}</StyledHeading>
              <CloseIconButton onClick={onClickCallback}>
                <CloseIcon style={{ fill: theme.palette.text.primary }} />
              </CloseIconButton>
            </HeadingBox>
            <Typography>{message}</Typography>
            {sedimentType && (
              <>
                <ul>
                  {hints.sedimentType.bullets.map((bullet) => (
                    <li key={bullet}>{bullet}</li>
                  ))}
                </ul>
                <Typography>{hints.sedimentType.message2}</Typography>
              </>
            )}
          </StyledPaper>
        </ClickAwayListener>
      </Box>
    </DialogOverlay>
  );
};
