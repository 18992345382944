import type { ThemeOptions } from '@mui/material/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import {
  FONT_FAMILY,
  PRIMARY,
  SECONDARY,
  ERROR,
  ON_SURFACE,
  SURFACE,
  ON_PRIMARY,
  MENU,
  ON_SURFACE_SECONDARY,
  TERTIARY,
  USER_MENU,
  OPAQUE_SURFACE,
  DISABLED_BBACKGROUND,
  BORDERS,
  BACKGROUND_SECONDARY,
  BACKGROUND_TERTIARY,
  ERROR_BACKGROUND,
  TERTIARY_MUTED,
  ON_TERTIARY_MUTED,
  ON_BACKGROUND,
  BACKGROUND_DISTANT,
  ON_MENU,
} from './constants';

const fontFamily = [
  ...FONT_FAMILY.map((family) => `"${family}"`),
  'sans-serif',
].join(',');

const makeTheme = (options: ThemeOptions) =>
  responsiveFontSizes(
    createTheme(
      deepmerge(
        {
          components: {
            MuiCssBaseline: {
              styleOverrides: {
                ':root': {
                  '--primary-color': PRIMARY,
                },
              },
            },
          },
          typography: {
            fontFamily,
          },
        },
        options,
      ),
    ),
  );

export const primaryTheme = makeTheme({
  palette: {
    mode: 'light',
    text: {
      primary: ON_SURFACE,
    },
    action: {
      disabledBackground: DISABLED_BBACKGROUND,
      disabled: TERTIARY,
    },
    primary: {
      main: PRIMARY,
    },
    menu: {
      main: MENU,
    },
    secondary: {
      main: SECONDARY,
    },
    tertiary: {
      main: TERTIARY,
      muted: TERTIARY_MUTED,
    },
    opaqueSurface: {
      main: OPAQUE_SURFACE,
    },
    onPrimary: {
      main: ON_PRIMARY,
    },
    surface: {
      main: SURFACE,
    },
    onTertiary: {
      main: ON_TERTIARY_MUTED,
    },
    onSurfaceSecondary: {
      main: ON_SURFACE_SECONDARY,
    },
    userMenu: {
      main: USER_MENU,
    },
    error: {
      main: ERROR,
    },
    errorBackground: {
      main: ERROR_BACKGROUND,
    },
    borders: {
      main: BORDERS,
    },
    backgroundSecondary: {
      main: BACKGROUND_SECONDARY,
    },
    backgroundTertiary: {
      main: BACKGROUND_TERTIARY,
    },
    onBackground: {
      main: ON_BACKGROUND,
    },
    backgroundDistant: {
      main: BACKGROUND_DISTANT,
    },
    onMenu: {
      main: ON_MENU,
    },
  },
});
