import type { TypographyProps } from '@mui/material';
import { Typography as MuiTypography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormH2 = styled(MuiTypography)<
  TypographyProps & { disabled?: boolean }
>(({ theme, disabled }) => ({
  color: disabled ? theme.palette.onTertiary.main : theme.palette.primary.main,

  '@media': {
    fontSize: '1.3rem',
    fontWeight: '400',
  },
}));
