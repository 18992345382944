import type { ReactNode } from 'react';
import { Component } from 'react';

import { Error } from '../generic';

type ErrorBoundaryProps = {
  children?: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  /*
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    logErrorToMyService(error, errorInfo);
  }
  */

  handleClick = () => this.setState({ hasError: false });

  public render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Error
          buttonText="Refresh"
          onClickCallback={this.handleClick}
          open={hasError}
        >
          <p>
            <strong>ERROR </strong>We appologize. An unexpected error happened.
          </p>
        </Error>
      );
    }

    return children;
  }
}
