import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CalculationResult = {
  __typename?: 'CalculationResult';
  /** Highlighted device / device combination */
  mainHighlight: Selection;
  /** Other devices / combinations of devices */
  rest?: Maybe<Array<Selection>>;
  /** Second highlighted device / device combination (optional) */
  secondaryHighlight?: Maybe<Selection>;
};

export type Enums = {
  __typename?: 'Enums';
  applicationType: StringEnum;
  maximumHydraulicFlowReturnPeriodType: IntEnum;
  sedimentType: StringEnum;
  treatmentReturnPeriodType: IntEnum;
};

export type IntEnum = {
  __typename?: 'IntEnum';
  id: Scalars['ID'];
  values: Array<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  /** url of product image */
  image: Scalars['String'];
  label: Scalars['String'];
  material?: Maybe<Scalars['String']>;
  pricePerPiece: Scalars['Float'];
  priceTotal: Scalars['Float'];
  productNumber: Scalars['String'];
  quantity: Scalars['Int'];
};

export type ProductsCalculatedFlowInput = {
  /** see query enums/applicationType */
  application: Scalars['String'];
  infiltration: Scalars['Boolean'];
  /** see query enums/maximumHydraulicFlowReturnPeriodType */
  maximumHydraulicFlowReturnPeriod: Scalars['Int'];
  /** see query enums/sedimentType */
  sedimentType: Scalars['String'];
  treatmentArea: Scalars['Int'];
  treatmentReturnPeriod: Scalars['Int'];
};

export type ProductsInput = {
  /** see query enums/applicationType */
  application: Scalars['String'];
  bypassFlowRate: Scalars['Boolean'];
  infiltration: Scalars['Boolean'];
  maximumHydraulicFlow?: InputMaybe<Scalars['Float']>;
  /** see query enums/sedimentType */
  sedimentType: Scalars['String'];
  treatmentFlowRate: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  enum?: Maybe<Enums>;
  me?: Maybe<UserInfo>;
  /** Determines product selection when Flow Rate is specified */
  products?: Maybe<CalculationResult>;
  /** Determines product selection when Flow Rate is calculated */
  productsCalculatedFlow?: Maybe<CalculationResult>;
};

export type QueryProductsArgs = {
  input: ProductsInput;
};

export type QueryProductsCalculatedFlowArgs = {
  input: ProductsCalculatedFlowInput;
};

export enum Recommendation {
  ACO_RECOMMENDS = 'ACO_RECOMMENDS',
  HIGHER_EFFICIENCY = 'HIGHER_EFFICIENCY',
}

export type Selection = {
  __typename?: 'Selection';
  /** Flow Rate Sediment Removal */
  FRSR: Scalars['String'];
  /** Heavy Metal Removal */
  HMR: Scalars['String'];
  /** currency */
  currency: Scalars['String'];
  /** url used for datasheet download */
  dataSheetZip?: Maybe<Scalars['String']>;
  /** details of every recommended products */
  details: Array<Maybe<Product>>;
  /** urls of recommended products' images */
  images: Array<Maybe<Scalars['String']>>;
  /** labels of recommended product */
  labels: Array<Maybe<Scalars['String']>>;
  /** total price of selection */
  price: Scalars['Float'];
  /** what recommendation(s) does selection satisfy */
  recommendation: Recommendation;
};

export type StringEnum = {
  __typename?: 'StringEnum';
  id: Scalars['ID'];
  values: Array<Scalars['String']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  countryName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  langCode?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  notification?: Maybe<Array<Maybe<Scalars['String']>>>;
  officeCode?: Maybe<Scalars['String']>;
  officeLangCode?: Maybe<Scalars['String']>;
  officeName?: Maybe<Scalars['String']>;
  priceListId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['String']>;
};

export type StringEnumFragment = {
  __typename?: 'StringEnum';
  id: string;
  values: Array<string>;
};

export type IntEnumFragment = {
  __typename?: 'IntEnum';
  id: string;
  values: Array<number>;
};

export type EnumsFragment = {
  __typename?: 'Enums';
  maximumHydraulicFlowReturnPeriodType: {
    __typename?: 'IntEnum';
    id: string;
    values: Array<number>;
  };
  applicationType: {
    __typename?: 'StringEnum';
    id: string;
    values: Array<string>;
  };
  sedimentType: {
    __typename?: 'StringEnum';
    id: string;
    values: Array<string>;
  };
};

export type EnumsQueryVariables = Exact<{ [key: string]: never }>;

export type EnumsQuery = {
  __typename?: 'Query';
  enum?: {
    __typename?: 'Enums';
    maximumHydraulicFlowReturnPeriodType: {
      __typename?: 'IntEnum';
      id: string;
      values: Array<number>;
    };
    applicationType: {
      __typename?: 'StringEnum';
      id: string;
      values: Array<string>;
    };
    sedimentType: {
      __typename?: 'StringEnum';
      id: string;
      values: Array<string>;
    };
  } | null;
};

export type UserInfoFragment = {
  __typename?: 'UserInfo';
  userId?: string | null;
  email?: string | null;
  countryName?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  displayName?: string | null;
  notification?: Array<string | null> | null;
  priceListId?: string | null;
  langCode?: string | null;
  officeCode?: string | null;
  region?: string | null;
  officeName?: string | null;
  roles?: Array<string | null> | null;
  officeLangCode?: string | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UserInfo';
    userId?: string | null;
    email?: string | null;
    countryName?: string | null;
    firstname?: string | null;
    lastname?: string | null;
    displayName?: string | null;
    notification?: Array<string | null> | null;
    priceListId?: string | null;
    langCode?: string | null;
    officeCode?: string | null;
    region?: string | null;
    officeName?: string | null;
    roles?: Array<string | null> | null;
    officeLangCode?: string | null;
  } | null;
};

export type ProductFragment = {
  __typename?: 'Product';
  label: string;
  productNumber: string;
  material?: string | null;
  quantity: number;
  pricePerPiece: number;
  priceTotal: number;
  image: string;
};

export type SelectionFragment = {
  __typename?: 'Selection';
  labels: Array<string | null>;
  images: Array<string | null>;
  price: number;
  currency: string;
  FRSR: string;
  HMR: string;
  dataSheetZip?: string | null;
  recommendation: Recommendation;
  details: Array<{
    __typename?: 'Product';
    label: string;
    productNumber: string;
    material?: string | null;
    quantity: number;
    pricePerPiece: number;
    priceTotal: number;
    image: string;
  } | null>;
};

export type ProductsQueryVariables = Exact<{
  input: ProductsInput;
}>;

export type ProductsQuery = {
  __typename?: 'Query';
  products?: {
    __typename?: 'CalculationResult';
    mainHighlight: {
      __typename?: 'Selection';
      labels: Array<string | null>;
      images: Array<string | null>;
      price: number;
      currency: string;
      FRSR: string;
      HMR: string;
      dataSheetZip?: string | null;
      recommendation: Recommendation;
      details: Array<{
        __typename?: 'Product';
        label: string;
        productNumber: string;
        material?: string | null;
        quantity: number;
        pricePerPiece: number;
        priceTotal: number;
        image: string;
      } | null>;
    };
    secondaryHighlight?: {
      __typename?: 'Selection';
      labels: Array<string | null>;
      images: Array<string | null>;
      price: number;
      currency: string;
      FRSR: string;
      HMR: string;
      dataSheetZip?: string | null;
      recommendation: Recommendation;
      details: Array<{
        __typename?: 'Product';
        label: string;
        productNumber: string;
        material?: string | null;
        quantity: number;
        pricePerPiece: number;
        priceTotal: number;
        image: string;
      } | null>;
    } | null;
    rest?: Array<{
      __typename?: 'Selection';
      labels: Array<string | null>;
      images: Array<string | null>;
      price: number;
      currency: string;
      FRSR: string;
      HMR: string;
      dataSheetZip?: string | null;
      recommendation: Recommendation;
      details: Array<{
        __typename?: 'Product';
        label: string;
        productNumber: string;
        material?: string | null;
        quantity: number;
        pricePerPiece: number;
        priceTotal: number;
        image: string;
      } | null>;
    }> | null;
  } | null;
};

export type ProductsCalculatedFlowQueryVariables = Exact<{
  input: ProductsCalculatedFlowInput;
}>;

export type ProductsCalculatedFlowQuery = {
  __typename?: 'Query';
  productsCalculatedFlow?: {
    __typename?: 'CalculationResult';
    mainHighlight: {
      __typename?: 'Selection';
      labels: Array<string | null>;
      images: Array<string | null>;
      price: number;
      currency: string;
      FRSR: string;
      HMR: string;
      dataSheetZip?: string | null;
      recommendation: Recommendation;
      details: Array<{
        __typename?: 'Product';
        label: string;
        productNumber: string;
        material?: string | null;
        quantity: number;
        pricePerPiece: number;
        priceTotal: number;
        image: string;
      } | null>;
    };
    secondaryHighlight?: {
      __typename?: 'Selection';
      labels: Array<string | null>;
      images: Array<string | null>;
      price: number;
      currency: string;
      FRSR: string;
      HMR: string;
      dataSheetZip?: string | null;
      recommendation: Recommendation;
      details: Array<{
        __typename?: 'Product';
        label: string;
        productNumber: string;
        material?: string | null;
        quantity: number;
        pricePerPiece: number;
        priceTotal: number;
        image: string;
      } | null>;
    } | null;
    rest?: Array<{
      __typename?: 'Selection';
      labels: Array<string | null>;
      images: Array<string | null>;
      price: number;
      currency: string;
      FRSR: string;
      HMR: string;
      dataSheetZip?: string | null;
      recommendation: Recommendation;
      details: Array<{
        __typename?: 'Product';
        label: string;
        productNumber: string;
        material?: string | null;
        quantity: number;
        pricePerPiece: number;
        priceTotal: number;
        image: string;
      } | null>;
    }> | null;
  } | null;
};

export const IntEnumFragmentDoc = gql`
  fragment intEnum on IntEnum {
    id
    values
  }
`;
export const StringEnumFragmentDoc = gql`
  fragment stringEnum on StringEnum {
    id
    values
  }
`;
export const EnumsFragmentDoc = gql`
  fragment enums on Enums {
    maximumHydraulicFlowReturnPeriodType {
      ...intEnum
    }
    applicationType {
      ...stringEnum
    }
    sedimentType {
      ...stringEnum
    }
  }
  ${IntEnumFragmentDoc}
  ${StringEnumFragmentDoc}
`;
export const UserInfoFragmentDoc = gql`
  fragment userInfo on UserInfo {
    userId
    email
    countryName
    firstname
    lastname
    displayName
    notification
    priceListId
    langCode
    officeCode
    region
    officeName
    roles
    officeLangCode
  }
`;
export const ProductFragmentDoc = gql`
  fragment product on Product {
    label
    productNumber
    material
    quantity
    pricePerPiece
    priceTotal
    image
  }
`;
export const SelectionFragmentDoc = gql`
  fragment selection on Selection {
    labels
    images
    price
    currency
    FRSR
    HMR
    dataSheetZip
    recommendation
    details {
      ...product
    }
  }
  ${ProductFragmentDoc}
`;
export const EnumsDocument = gql`
  query Enums {
    enum {
      ...enums
    }
  }
  ${EnumsFragmentDoc}
`;

/**
 * __useEnumsQuery__
 *
 * To run a query within a React component, call `useEnumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnumsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnumsQuery(
  baseOptions?: Apollo.QueryHookOptions<EnumsQuery, EnumsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnumsQuery, EnumsQueryVariables>(
    EnumsDocument,
    options,
  );
}
export function useEnumsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnumsQuery, EnumsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnumsQuery, EnumsQueryVariables>(
    EnumsDocument,
    options,
  );
}
export type EnumsQueryHookResult = ReturnType<typeof useEnumsQuery>;
export type EnumsLazyQueryHookResult = ReturnType<typeof useEnumsLazyQuery>;
export type EnumsQueryResult = Apollo.QueryResult<
  EnumsQuery,
  EnumsQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      ...userInfo
    }
  }
  ${UserInfoFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ProductsDocument = gql`
  query Products($input: ProductsInput!) {
    products(input: $input) {
      mainHighlight {
        ...selection
      }
      secondaryHighlight {
        ...selection
      }
      rest {
        ...selection
      }
    }
  }
  ${SelectionFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options,
  );
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsQuery,
    ProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options,
  );
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<
  typeof useProductsLazyQuery
>;
export type ProductsQueryResult = Apollo.QueryResult<
  ProductsQuery,
  ProductsQueryVariables
>;
export const ProductsCalculatedFlowDocument = gql`
  query productsCalculatedFlow($input: ProductsCalculatedFlowInput!) {
    productsCalculatedFlow(input: $input) {
      mainHighlight {
        ...selection
      }
      secondaryHighlight {
        ...selection
      }
      rest {
        ...selection
      }
    }
  }
  ${SelectionFragmentDoc}
`;

/**
 * __useProductsCalculatedFlowQuery__
 *
 * To run a query within a React component, call `useProductsCalculatedFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsCalculatedFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsCalculatedFlowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsCalculatedFlowQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsCalculatedFlowQuery,
    ProductsCalculatedFlowQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsCalculatedFlowQuery,
    ProductsCalculatedFlowQueryVariables
  >(ProductsCalculatedFlowDocument, options);
}
export function useProductsCalculatedFlowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsCalculatedFlowQuery,
    ProductsCalculatedFlowQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsCalculatedFlowQuery,
    ProductsCalculatedFlowQueryVariables
  >(ProductsCalculatedFlowDocument, options);
}
export type ProductsCalculatedFlowQueryHookResult = ReturnType<
  typeof useProductsCalculatedFlowQuery
>;
export type ProductsCalculatedFlowLazyQueryHookResult = ReturnType<
  typeof useProductsCalculatedFlowLazyQuery
>;
export type ProductsCalculatedFlowQueryResult = Apollo.QueryResult<
  ProductsCalculatedFlowQuery,
  ProductsCalculatedFlowQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
