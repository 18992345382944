import { createContext } from 'react';

import {
  TreatmentReturnPeriodMeasure,
  MaximumHydraulicFlowReturnPeriodMeasure,
  type FormContextState,
} from '../../types';

export const defaultData: FormContextState = {
  flowRate: true,
  stepOne: {
    leftColumn: {
      bypassFlow: false,
      maximumHydraulicFlow: '',
      treatmentFlowRate: '',
    },
    rightColumn: {
      maximumHydraulicFlowReturnPeriod: '',
      treatmentArea: '',
      treatmentReturnPeriod: 1,
      treatmentReturnPeriodMeasure: TreatmentReturnPeriodMeasure.TreatmentArea,
      maximumHydraulicFlowReturnPeriodMeasure:
        MaximumHydraulicFlowReturnPeriodMeasure.MaximumHydraulicFlowReturnPeriod,
    },
  },
  stepTwo: {
    application: '',
    infiltration: false,
    sedimentType: 'Average (DE, AT, CH)',
  },
};

type FormContextStateEx = FormContextState & {
  setData: (payload: FormContextState) => void;
  resetData: () => void;
};

export const formContextDefaultState: FormContextStateEx = {
  ...defaultData,
  setData: () => ({}),
  resetData: () => ({}),
};

export const formContext = createContext<FormContextStateEx>(
  formContextDefaultState,
);
