/* eslint-disable @typescript-eslint/no-misused-promises */
import { ClickAwayListener } from '@mui/base';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  SvgIcon,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Box,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import type { KeyboardEvent } from 'react';

import User from '../../../../public/icons/user.svg';
import { useUserContext, useLogout } from '../../../hooks';

import { UserInfo } from './UserInfo';

const StyledUserContainer = styled(SvgIcon)(({ theme }) => ({
  fill: theme.palette.onPrimary.main,
  marginRight: '11px',
  width: '18px',
  height: '18px',
}));

const StyledList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.userMenu.main,
  borderRadius: '14px',
  position: 'absolute',
  width: '100%',
  boxShadow: '0px 2px 3px #0000001a',
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  color: theme.palette.text.primary,
  padding: '6px 40px 6px 0px',
  textAlign: 'right',
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  padding: '0px',

  '& .MuiListItemText-root': {
    margin: '0px',
  },
}));

export const UserMenu = () => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isUserInfoShown, setIsUserInfoShown] = useState(false);
  const [logoutText, setLogoutText] = useState('logout');
  const { user } = useUserContext();
  const handleLogout = useLogout();

  const logout = async () => {
    setLogoutText('logging out...');
    await handleLogout();
  };

  const expandMenu = () => setIsExpanded(true);

  const showUserInfo = () =>
    setIsUserInfoShown((previousIsShown) => !previousIsShown);

  const handleClickAway = () => {
    setIsUserInfoShown(false);
    setIsExpanded(false);
  };

  const infoKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') showUserInfo();
  };

  const logoutDown = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') await logout();
  };

  if (user) {
    return (
      <Box>
        <List sx={{ zIndex: 100, padding: '0px' }}>
          <li>
            <ListItemButton
              onClick={expandMenu}
              sx={{ '@media': { paddingRight: '0px' } }}
            >
              <StyledUserContainer>
                <User />
              </StyledUserContainer>
              {user.firstname && user.lastname && (
                <ListItemText
                  primary={`${user.firstname} ${user.lastname}`}
                  sx={{ marginRight: '16px' }}
                />
              )}
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </li>
          <li>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <ClickAwayListener onClickAway={handleClickAway}>
                <StyledList>
                  <StyledListItemButton onKeyDown={infoKeyDown}>
                    <StyledListItemText
                      onClick={showUserInfo}
                      primary="user info"
                      sx={{
                        color: isUserInfoShown
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                      }}
                    />
                  </StyledListItemButton>
                  <StyledListItemButton onKeyDown={logoutDown}>
                    <StyledListItemText onClick={logout} primary={logoutText} />
                  </StyledListItemButton>
                  {isUserInfoShown && <UserInfo />}
                </StyledList>
              </ClickAwayListener>
            </Collapse>
          </li>
        </List>
      </Box>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
