import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { SelectProps, SelectChangeEvent } from '@mui/material';
import { Select as MuiSelect } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ReactNode } from 'react';
import { useState, forwardRef } from 'react';

type SelectedValue = string | number;

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      children,
      error,
      IconComponent = ExpandMoreIcon,
      MenuProps,
      displayEmpty = true,
      placeholder,
      defaultValue = '',
      disabled,
      sx,
      onChange,
      ...props
    },
    hookFormRef,
  ) => {
    const theme = useTheme();
    const [isSelected, setIsSelected] = useState(!!defaultValue);
    const [value, setValue] = useState<SelectedValue>(
      defaultValue as SelectedValue,
    );
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));

    const handleChange = (
      event: SelectChangeEvent<SelectedValue>,
      child: ReactNode,
    ) => {
      if (!isSelected) setIsSelected(true);
      setValue(event.target.value);
      if (onChange) onChange(event, child);
    };

    return (
      <MuiSelect
        {...props}
        ref={hookFormRef}
        IconComponent={IconComponent}
        defaultValue={defaultValue as SelectedValue}
        disabled={disabled}
        displayEmpty={displayEmpty}
        error={error}
        onChange={handleChange}
        renderValue={(selected) => selected || placeholder}
        value={value}
        MenuProps={{
          sx: {
            '& .MuiMenu-paper': {
              borderRadius: '28px',
              boxShadow: '0px 2px 3px #00000010',
              backgroundColor: '#F4F7F9',

              '& ul': {
                padding: '20px 0px',
                border: `0.5px solid ${theme.palette.backgroundTertiary.main}`,
                borderRadius: '28px',
              },

              '& li': {
                fontFamily: 'Md',
                fontWeight: '500',
                padding: '10px 0px 10px 35px',
                color: theme.palette.onSurfaceSecondary.main,
              },
            },
          },
          ...MenuProps,
        }}
        sx={{
          zIndex: '1',
          width: isLg ? '350px' : '100%',

          '&.MuiInputBase-root': {
            borderRadius: '33px',
            color: !isSelected
              ? theme.palette.tertiary.main
              : theme.palette.primary.main,
            background:
              disabled || (!isSelected && !error)
                ? theme.palette.backgroundTertiary.main
                : error
                ? theme.palette.errorBackground.main
                : theme.palette.backgroundSecondary.main,

            '&.Mui-focused fieldset ': {
              background: theme.palette.backgroundSecondary.main,
              border: `2px solid ${theme.palette.borders.main}`,
              zIndex: '-98',
            },

            '& .Mui-disabled': {
              textFillColor: theme.palette.backgroundTertiary.main,
            },
          },

          '& .MuiInputBase-input': {
            padding: '11px 30px',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border:
              disabled || (!isSelected && !error)
                ? 'none'
                : error
                ? `2px solid ${theme.palette.error.main}`
                : `1px solid ${theme.palette.borders.main}`,
          },

          '& .MuiSelect-icon': {
            marginRight: '12px',
            color: theme.palette.primary.main,

            '&.Mui-disabled': {
              display: 'none',
            },
          },
          ...sx,
        }}
      >
        {children}
      </MuiSelect>
    );
  },
);
