import type { SelectionFragment } from 'aco-swt-graphql/lib/graphql-model-client';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { useFormContext } from './useFormContext';

export const useExportSummary = (product: SelectionFragment) => {
  const { labels, FRSR, HMR, price, details } = product;
  const { stepTwo, stepOne } = useFormContext();

  const {
    leftColumn: { bypassFlow, maximumHydraulicFlow, treatmentFlowRate },
  } = stepOne;
  const { application, infiltration, sedimentType } = stepTwo;

  const exportSummary = async () => {
    const response = await fetch('/sheets/template.xlsx');
    const arrayBuffer = await response.arrayBuffer();

    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const worksheet = workbook.worksheets[0];

    worksheet.getCell('C4').value = new Date();

    worksheet.getCell('C7').value = treatmentFlowRate;
    worksheet.getCell('C8').value = bypassFlow ? 'Yes' : 'No'; // rename to first flush
    worksheet.getCell('C9').value = maximumHydraulicFlow;

    worksheet.getCell('C13').value = application;
    worksheet.getCell('C14').value = sedimentType;
    worksheet.getCell('C15').value = infiltration ? 'Yes' : 'No';

    worksheet.getCell('C19').value = application;
    worksheet.getCell('B20').value = labels[0] ?? '';
    worksheet.getCell('B21').value = labels[1] ?? '';
    worksheet.getCell(
      'C20',
    ).value = `Design Flow Rate Sediment Removal: ${FRSR}`;
    worksheet.getCell('C21').value = `Design Heavy Metal Removal: ${HMR}`;

    worksheet.getCell('C23').value = labels[0] ?? '';
    worksheet.getCell('C24').value = details[0]?.productNumber;
    worksheet.getCell('C25').value = details[0]?.material;
    worksheet.getCell('C26').value = details[0]?.quantity;
    worksheet.getCell('C27').value = details[0]?.pricePerPiece;
    worksheet.getCell('C28').value = details[0]?.priceTotal;

    worksheet.getCell('C30').value = labels[1] ?? '';
    worksheet.getCell('C31').value = details[1]?.productNumber;
    worksheet.getCell('C32').value = details[1]?.material;
    worksheet.getCell('C33').value = details[1]?.quantity;
    worksheet.getCell('C34').value = details[1]?.pricePerPiece;
    worksheet.getCell('C35').value = details[1]?.priceTotal;

    worksheet.getCell('C37').value = price;

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Summary_Export.xlsx');
  };

  return exportSummary;
};
