import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useStepsContext } from '../../../hooks';

const LocationName = styled(Typography)({
  '@media': {
    fontSize: '1.5rem',
    fontWeight: '400',
    marginLeft: '9px',
    cursor: 'default',
    textAlign: 'center',
  },
});

type LocationProps = {
  isSelectionTool: boolean;
};

export const Location = ({ isSelectionTool }: LocationProps) => {
  const { activeName } = useStepsContext();

  return (
    <LocationName variant="h2">
      {isSelectionTool ? activeName : 'SWT QUICK SELECTION TOOL'}
    </LocationName>
  );
};
