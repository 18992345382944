import { useFormContext } from './useFormContext';
import { useStepsContext } from './useStepsContext';

export const useReset = () => {
  const { resetData } = useFormContext();
  const { startAgain } = useStepsContext();

  const reset = () => {
    resetData();
    startAgain();
  };

  return reset;
};
