import { ModalUnstyled } from '@mui/base';
import { styled } from '@mui/material/styles';

export const DialogOverlay = styled(ModalUnstyled)({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#ffffff60',
  zIndex: '101',
  width: '100%',
  height: '100%',
  left: '0',
  top: '0',
  right: '0',
  bottom: '0',
  overflow: 'scroll',
});
