import { ApolloProvider } from '@apollo/client';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { getCookie } from 'cookies-next';
import Head from 'next/head';

import { useApollo } from '../apollo/apolloClient';
import { ErrorBoundary } from '../components/errorBoundary/ErrorBoundary';
import { GTMProvider } from '../components/gtmProvider';
import { Layout } from '../components/layout';
import { Timeout } from '../components/timeout/Timeout';
import {
  StepsContextProvider,
  FormContextProvider,
  UserContextProvider,
} from '../contexts';
import { createEmotionCache } from '../styles';
import { primaryTheme } from '../styles/theme';
import type { AppType, AppContextEx } from '../types';

import '../styles/style.css';

const clientSideEmotionCache = createEmotionCache();

const App: AppType = ({
  Component,
  emotionCache,
  pageProps,
  gqlUrl,
  userToken,
  authnCookieName,
  configuratorLink,
  gtmId,
}) => {
  const apolloClient = useApollo(
    pageProps as Record<string, unknown>,
    gqlUrl ?? '',
  );

  return (
    <ApolloProvider client={apolloClient}>
      <CacheProvider value={emotionCache ?? clientSideEmotionCache}>
        <ThemeProvider theme={primaryTheme}>
          <Head>
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
            <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
            <meta charSet="utf8" />
          </Head>

          <GTMProvider gtmId={gtmId} />
          <ErrorBoundary>
            <UserContextProvider
              authnCookieName={authnCookieName}
              defaultUser={userToken}
            >
              <Timeout>
                <StepsContextProvider>
                  <FormContextProvider>
                    <Layout configuratorLink={configuratorLink}>
                      <Component {...pageProps} />
                    </Layout>
                  </FormContextProvider>
                </StepsContextProvider>
              </Timeout>
            </UserContextProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </CacheProvider>
    </ApolloProvider>
  );
};

App.getInitialProps = (context: AppContextEx) => {
  const { res, req } = context.ctx;

  // server
  if (res && req) {
    const { gqlUrl, authnCookieName, url } = req;
    const userToken = getCookie(authnCookieName ?? '', { req, res });

    if (!userToken && !url?.startsWith('/login')) {
      res.writeHead(307, { Location: '/login' });
      res.end();
    }

    const configuratorLink = process.env.CONFIGURATOR_LINK;
    const gtmId = process.env.GOOGLE_ANALYTICS_CODE;

    return {
      pageProps: {
        gqlUrl,
      },
      userToken,
      authnCookieName,
      configuratorLink,
      gtmId,
    };
  }

  return {
    pageProps: {},
  };
};

export default App;
