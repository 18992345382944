import { Box } from '@mui/material';
import type { BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

import { Paper } from '../../generic';

type UserInfoContainerProps = {
  children: ReactNode;
  isEmpty?: boolean;
};

type WrapperProps = BoxProps & {
  isEmpty?: boolean;
};

const Wrapper = styled(Box, {
  shouldForwardProp: (property) => property !== 'isEmpty',
})<WrapperProps>(({ isEmpty }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  textAlign: !isEmpty ? 'inherit' : 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.onPrimary.main,
  position: 'absolute',
  top: '90px',
  borderRadius: '14px',
  boxShadow: '0px 2px 3px #0000001a',
  padding: '12px',

  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 24px)',
  },
}));

export const UserInfoContainer = ({
  children,
  isEmpty,
}: UserInfoContainerProps) => {
  return (
    <Wrapper isEmpty={isEmpty}>
      <StyledPaper>{children}</StyledPaper>
    </Wrapper>
  );
};
