import Image from 'next/image';
import type { ImageProps } from 'next/image';
import { useState } from 'react';

import { imageLoader, makeSkeleton } from '../../utils';

export const ImageWithFallback = ({
  src,
  loader = imageLoader,
  width,
  height,
  ...props
}: ImageProps) => {
  const [imageSource, setImageSource] = useState(src);

  const handleError = () => setImageSource('/icons/placeholder.svg');

  return (
    <Image
      {...props}
      blurDataURL={makeSkeleton()}
      height={height}
      loader={loader}
      onError={handleError}
      placeholder="blur"
      src={imageSource}
      width={width}
    />
  );
};
