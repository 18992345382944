import { useReset } from './useReset';
import { useUserContext } from './useUserContext';

export const useLogout = () => {
  const { resetUserContext } = useUserContext();
  const reset = useReset();

  const handleLogout = async () => {
    try {
      await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include',
      });
      reset();
      resetUserContext();
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return handleLogout;
};
