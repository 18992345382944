import { Container, Typography, Box, IconButton, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';

import Home from '../../../../public/icons/home.svg';
import Logo from '../../../../public/icons/logo.svg';
import { useReset, useStepsContext } from '../../../hooks';
import { MainButton, Tooltip } from '../../generic';

import { Location } from './Location';
import { UserMenu } from './UserMenu';

const IconContainer = styled(IconButton)(({ theme }) => ({
  fill: theme.palette.onPrimary.main,
  padding: '0',
  width: '30px',
  height: '30px',
  svg: { width: '22px', height: '22px' },
  margin: '0 16px',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledLogo = styled(SvgIcon)({
  cursor: 'pointer',
  width: '60px',
  height: '46px',
});

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const LocationContainer = styled(StyledBox)(({ theme }) => ({
  justifyContent: 'center',
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const MenuContainer = styled(StyledBox)(({ theme }) => ({
  justifyContent: 'right',
  gridColumnEnd: 'span 2',
  [theme.breakpoints.up('md')]: {
    gridColumnEnd: 'span 1',
  },
}));

const StyledHeader = styled('header')(({ theme }) => ({
  background: theme.palette.tertiary.main,
  color: theme.palette.onPrimary.main,
}));

const HeaderContainer = styled(Container)(({ theme }) => ({
  display: 'grid',
  '@media': {
    padding: '32px 12px',
    [theme.breakpoints.up('lg')]: {
      padding: '32px 70px',
    },
    maxWidth: '1440px',
  },
}));

const Claim = styled(Typography)(({ theme }) => ({
  margin: '21px 0 0 18px',
  fontWeight: '500',
  cursor: 'default',
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

const ButtonBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const PipeSpan = styled('span')(({ theme }) => ({
  cursor: 'pointer',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const MenuBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

const HomeIconAndUserMenuBox = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const tooltip = 'Reset data';

type HeaderProps = {
  configuratorLink?: string;
};

export const Header = ({ configuratorLink }: HeaderProps) => {
  const { pathname } = useRouter();
  const isFound = pathname !== '/404';
  const isLoginPage = pathname === '/login';
  const isSelectionTool = pathname === '/';
  const reset = useReset();
  const { activeIndex } = useStepsContext();

  const resetSteps = () => {
    reset();
    if (activeIndex === 0) window.location.reload();
  };

  return isFound ? (
    <StyledHeader>
      <HeaderContainer
        sx={{
          gridTemplateColumns: isLoginPage ? '1fr 30% 1fr' : '1fr 17% 1fr',
        }}
      >
        <StyledBox>
          <Tooltip
            followCursor
            placement="bottom"
            title={isLoginPage ? '' : tooltip}
          >
            <StyledLogo onClick={resetSteps} viewBox="0 0 60 46">
              <Logo />
            </StyledLogo>
          </Tooltip>
          <Claim>ACO. we care for water</Claim>
        </StyledBox>
        <LocationContainer>
          <Location isSelectionTool={isSelectionTool} />
        </LocationContainer>
        <MenuContainer>
          {!isLoginPage && (
            <MenuBox>
              <ButtonBox>
                <a href={configuratorLink} rel="noreferrer" target="_blank">
                  <MainButton>GO TO CONFIGURATOR</MainButton>
                </a>
              </ButtonBox>
              <HomeIconAndUserMenuBox>
                <Tooltip followCursor placement="bottom" title={tooltip}>
                  <IconContainer onClick={resetSteps}>
                    <Home />
                  </IconContainer>
                </Tooltip>
                <PipeSpan>|</PipeSpan>
                <UserMenu />
              </HomeIconAndUserMenuBox>
            </MenuBox>
          )}
        </MenuContainer>
      </HeaderContainer>
    </StyledHeader>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};
